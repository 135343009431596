export default class UtilsArray {
  public static isEmptyArray(arr: []) {
    // Verifica se o array está vazio
    if (arr.length === 0) {
      return true;
    }

    // Verifica se todos os elementos no array são objetos vazios
    return arr.every(item => {
      // Verifica se o item é um objeto e não é null
      if (typeof item === 'object' && item !== null) {
        // Verifica se o objeto não tem propriedades
        return Object.keys(item).length === 0;
      }
      // Se algum item não for um objeto vazio, retorna false
      return false;
    });
  }

  public static isEmptyObject(obj: {}) {
    return typeof obj === 'object' && obj !== null && Object.keys(obj).length === 0;
  };

  public static isObject(item: {}) {
    return typeof item === 'object' && item !== null && !Array.isArray(item);
  };
}